import styled from "styled-components"
import React from "react"

const Row = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const Col = styled.div`
  
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => (100 * props.tiles) / 24}%;
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const ContentRow = ({ components }) => {
  if (!components) return null

  const parts = components.length

  if (!components.length) {
    return (
      <Row>
        <Col tiles={24}>
          <h1>PLACEHOLDER</h1>
        </Col>
      </Row>
    )
  } else {
    return (
      <Row>
        {components.map((component, key) => (
          <Col key={key} tiles={24 / parts}>
            {component}
          </Col>
        ))}
      </Row>
    )
  }
}
